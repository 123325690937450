<template>
  <div class="MainContent">
    <el-tabs
      type="border-card"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <el-tab-pane :label="totalLabel">
        <form-comp  :input-data="dataAll" ></form-comp>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
const FormComp = ()=> import("./FormCopm.vue")
export default {
  name: "MainContent",
  components: {
    FormComp,
  },
  props: {
    dataAll: {
      type: Object,
      dafault() {
        return {};
      },
    },   
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      dafault: "哈哈哈",
    },
  },
  data() {
    return {
    };
  },
  computed: {
    totalLabel() {
      if (this.dataAll.level == 2) {
        return `栏目编辑-${this.dataAll.label}`;
      } else if (this.dataAll.level == 3) {
        return `${this.dataAll.label}-编辑`;
      }else if(Object.keys(this.dataAll).length === 0){
        return "编辑";
      }else{
        return `${this.dataAll.label}`
      }
    },
  },
  mounted() {},
  methods: {
    
  },
};
</script>

<style lang='scss' scoped>
</style>